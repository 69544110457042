// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.neuroglancer-side-panel-column {
  display: flex;
  flex-direction: column;
  min-width: 0px;
}

.neuroglancer-side-panel-row {
  display: flex;
  flex-direction: row;
  min-height: 0px;
}

.neuroglancer-side-panel {
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow: hidden;
}

.neuroglancer-side-panel-titlebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: sans-serif;
  font-size: 10pt;
  background-color: #03c;
  padding: 2px;
  color: white;
}

.neuroglancer-side-panel-title {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.neuroglancer-side-panel-drop-zone {
  z-index: 1000;
  display: none;
}

[data-neuroglancer-side-panel-drag] .neuroglancer-side-panel-drop-zone {
  display: block;
}

.neuroglancer-resize-gutter-vertical {
  height: 1px;
  background-color: #333;
  background-clip: content-box;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: row-resize;
}

.neuroglancer-resize-gutter-horizontal {
  width: 1px;
  background-color: #333;
  background-clip: content-box;
  padding-right: 2px;
  padding-left: 2px;
  cursor: col-resize;
}
`, "",{"version":3,"sources":["webpack://./src/ui/side_panel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,sBAAsB;EACtB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,OAAO;EACP,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,4BAA4B;EAC5B,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".neuroglancer-side-panel-column {\n  display: flex;\n  flex-direction: column;\n  min-width: 0px;\n}\n\n.neuroglancer-side-panel-row {\n  display: flex;\n  flex-direction: row;\n  min-height: 0px;\n}\n\n.neuroglancer-side-panel {\n  display: flex;\n  flex-direction: column;\n  min-width: 0px;\n  overflow: hidden;\n}\n\n.neuroglancer-side-panel-titlebar {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  font-family: sans-serif;\n  font-size: 10pt;\n  background-color: #03c;\n  padding: 2px;\n  color: white;\n}\n\n.neuroglancer-side-panel-title {\n  flex: 1;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.neuroglancer-side-panel-drop-zone {\n  z-index: 1000;\n  display: none;\n}\n\n[data-neuroglancer-side-panel-drag] .neuroglancer-side-panel-drop-zone {\n  display: block;\n}\n\n.neuroglancer-resize-gutter-vertical {\n  height: 1px;\n  background-color: #333;\n  background-clip: content-box;\n  padding-top: 2px;\n  padding-bottom: 2px;\n  cursor: row-resize;\n}\n\n.neuroglancer-resize-gutter-horizontal {\n  width: 1px;\n  background-color: #333;\n  background-clip: content-box;\n  padding-right: 2px;\n  padding-left: 2px;\n  cursor: col-resize;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
