// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

html,
body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
}

#neuroglancer-container {
  width: 100vw;
  overflow: hidden;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/ui/default_viewer.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;;EAEE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,OAAO;AACT","sourcesContent":["/**\n * @license\n * Copyright 2016 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\nhtml,\nbody {\n  margin: 0px;\n  padding: 0px;\n  overflow: hidden;\n  width: 100vw;\n  height: 100vh;\n  background-color: black;\n  color: white;\n}\n\nbody {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n}\n\n#neuroglancer-container {\n  width: 100vw;\n  overflow: hidden;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
