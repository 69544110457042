// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2017 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-layout-split-drop-zone {
  position: absolute;
  z-index: 1000;
}

.neuroglancer-drag-over {
  background-color: rgba(0, 0, 255, 0.5);
}

.neuroglancer-stack-layout-row > .neuroglancer-stack-layout-drop-placeholder {
  padding-left: 4px;
  padding-right: 4px;
  width: 1px;
  cursor: col-resize;
}

.neuroglancer-stack-layout-column
  > .neuroglancer-stack-layout-drop-placeholder {
  padding-top: 4px;
  padding-bottom: 4px;
  height: 1px;
  cursor: row-resize;
}

.neuroglancer-stack-layout-drop-placeholder {
  background-clip: content-box;
  background-color: #666;
  z-index: 1;
}

.neuroglancer-stack-layout-drop-placeholder.neuroglancer-drag-over {
  background-clip: border-box;
  background-color: rgba(128, 128, 255, 0.5);
}

.neuroglancer-stack-layout-drop-placeholder:first-child,
.neuroglancer-stack-layout-drop-placeholder:last-child {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/layer_groups_layout.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C;;AAEA;;EAEE,aAAa;AACf","sourcesContent":["/**\n * @license\n * Copyright 2017 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-layout-split-drop-zone {\n  position: absolute;\n  z-index: 1000;\n}\n\n.neuroglancer-drag-over {\n  background-color: rgba(0, 0, 255, 0.5);\n}\n\n.neuroglancer-stack-layout-row > .neuroglancer-stack-layout-drop-placeholder {\n  padding-left: 4px;\n  padding-right: 4px;\n  width: 1px;\n  cursor: col-resize;\n}\n\n.neuroglancer-stack-layout-column\n  > .neuroglancer-stack-layout-drop-placeholder {\n  padding-top: 4px;\n  padding-bottom: 4px;\n  height: 1px;\n  cursor: row-resize;\n}\n\n.neuroglancer-stack-layout-drop-placeholder {\n  background-clip: content-box;\n  background-color: #666;\n  z-index: 1;\n}\n\n.neuroglancer-stack-layout-drop-placeholder.neuroglancer-drag-over {\n  background-clip: border-box;\n  background-color: rgba(128, 128, 255, 0.5);\n}\n\n.neuroglancer-stack-layout-drop-placeholder:first-child,\n.neuroglancer-stack-layout-drop-placeholder:last-child {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
