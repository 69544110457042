// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.neuroglancer-layer-list-panel-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: 0px;
  min-height: 0px;
  flex-basis: 0px;
}

.neuroglancer-layer-list-panel-item {
  display: flex;
  flex-direction: row;
  padding: 2px;
  border: 1px solid #aaa;
  margin: 2px;
}

.neuroglancer-layer-list-panel-item[data-selected="true"] {
  border-color: #3c3;
}

.neuroglancer-layer-list-panel-item[data-archived="true"] {
  border-color: #666;
}

.neuroglancer-layer-list-panel-item[data-archived="true"]
  .neuroglancer-layer-side-panel-name,
.neuroglancer-layer-list-panel-item[data-archived="true"]
  .neuroglancer-icon
  svg {
  color: #999;
  stroke: #999;
}

.neuroglancer-layer-list-panel-item:hover {
  background-color: #333;
}

.neuroglancer-layer-list-panel-item-number {
  font-family: sans-serif;
  background-color: var(--layer-number-color);
  color: white;
  font-weight: bold;
  display: inline-block;
}

.neuroglancer-layer-list-panel-item:not(:hover)
  > .neuroglancer-layer-list-panel-item-delete {
  display: none;
}

.neuroglancer-layer-list-panel-item:not(:hover)
  > .neuroglancer-layer-list-panel-item-controls {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/ui/layer_list_panel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,cAAc;EACd,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;;;EAKE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,2CAA2C;EAC3C,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,aAAa;AACf","sourcesContent":[".neuroglancer-layer-list-panel-items {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  overflow: auto;\n  height: 0px;\n  min-height: 0px;\n  flex-basis: 0px;\n}\n\n.neuroglancer-layer-list-panel-item {\n  display: flex;\n  flex-direction: row;\n  padding: 2px;\n  border: 1px solid #aaa;\n  margin: 2px;\n}\n\n.neuroglancer-layer-list-panel-item[data-selected=\"true\"] {\n  border-color: #3c3;\n}\n\n.neuroglancer-layer-list-panel-item[data-archived=\"true\"] {\n  border-color: #666;\n}\n\n.neuroglancer-layer-list-panel-item[data-archived=\"true\"]\n  .neuroglancer-layer-side-panel-name,\n.neuroglancer-layer-list-panel-item[data-archived=\"true\"]\n  .neuroglancer-icon\n  svg {\n  color: #999;\n  stroke: #999;\n}\n\n.neuroglancer-layer-list-panel-item:hover {\n  background-color: #333;\n}\n\n.neuroglancer-layer-list-panel-item-number {\n  font-family: sans-serif;\n  background-color: var(--layer-number-color);\n  color: white;\n  font-weight: bold;\n  display: inline-block;\n}\n\n.neuroglancer-layer-list-panel-item:not(:hover)\n  > .neuroglancer-layer-list-panel-item-delete {\n  display: none;\n}\n\n.neuroglancer-layer-list-panel-item:not(:hover)\n  > .neuroglancer-layer-list-panel-item-controls {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
