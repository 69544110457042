// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2017 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-context-menu {
  position: absolute;
  z-index: 100;
  border: 1px solid white;
  background-color: black;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.5);
  font-family: sans-serif;
  font-size: 11pt;
  padding: 5px;
  color: white;
  outline: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/ui/context_menu.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,uBAAuB;EACvB,8CAA8C;EAC9C,uBAAuB;EACvB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,YAAY;AACd","sourcesContent":["/**\n * @license\n * Copyright 2017 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-context-menu {\n  position: absolute;\n  z-index: 100;\n  border: 1px solid white;\n  background-color: black;\n  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.5);\n  font-family: sans-serif;\n  font-size: 11pt;\n  padding: 5px;\n  color: white;\n  outline: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
