// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2021 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-layer-options-control-container
  .neuroglancer-layer-control-label-container {
  margin-right: auto;
}

.neuroglancer-layer-control-label-container {
  white-space: nowrap;
}

.neuroglancer-layer-control-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-layer-control-label-text-container {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.neuroglancer-layer-control-container {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-layer-control-control {
  flex: 1;
  margin-left: 5px;
  max-width: 500px;
}

select.neuroglancer-layer-control-control,
input[type="checkbox"].neuroglancer-layer-control-control,
input[type="color"].neuroglancer-layer-control-control {
  flex: initial;
}
`, "",{"version":3,"sources":["webpack://./src/widget/layer_control.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;;EAEE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,OAAO;EACP,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;;;EAGE,aAAa;AACf","sourcesContent":["/**\n * @license\n * Copyright 2021 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-layer-options-control-container\n  .neuroglancer-layer-control-label-container {\n  margin-right: auto;\n}\n\n.neuroglancer-layer-control-label-container {\n  white-space: nowrap;\n}\n\n.neuroglancer-layer-control-label {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.neuroglancer-layer-control-label-text-container {\n  display: flex;\n  flex-direction: row;\n  align-items: start;\n}\n\n.neuroglancer-layer-control-container {\n  margin-top: 1.5px;\n  margin-bottom: 1.5px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.neuroglancer-layer-control-control {\n  flex: 1;\n  margin-left: 5px;\n  max-width: 500px;\n}\n\nselect.neuroglancer-layer-control-control,\ninput[type=\"checkbox\"].neuroglancer-layer-control-control,\ninput[type=\"color\"].neuroglancer-layer-control-control {\n  flex: initial;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
