// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-statistics-panel-body {
  flex: 1;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font: 10pt sans-serif;
}

.neuroglancer-statistics-panel-body > table {
  position: relative;
  flex: 1;
  width: 100%;
}

.neuroglancer-statistics-panel-body > table > thead td {
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #333;
  font-weight: bold;
}

.neuroglancer-statistics-panel-body > table > tbody tr:hover {
  background-color: #336;
}
`, "",{"version":3,"sources":["webpack://./src/ui/statistics.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,OAAO;EACP,eAAe;EACf,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,QAAQ;EACR,UAAU;EACV,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["/**\n * @license\n * Copyright 2019 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-statistics-panel-body {\n  flex: 1;\n  flex-basis: 0px;\n  min-width: 0px;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  font: 10pt sans-serif;\n}\n\n.neuroglancer-statistics-panel-body > table {\n  position: relative;\n  flex: 1;\n  width: 100%;\n}\n\n.neuroglancer-statistics-panel-body > table > thead td {\n  position: sticky;\n  top: 0px;\n  z-index: 2;\n  background-color: #333;\n  font-weight: bold;\n}\n\n.neuroglancer-statistics-panel-body > table > tbody tr:hover {\n  background-color: #336;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
