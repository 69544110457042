// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2024 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-transfer-function-panel {
  height: 60px;
  border: 1px solid #666;
  margin-top: 5px;
}

.neuroglancer-transfer-function-color-picker {
  text-align: right;
}

.neuroglancer-transfer-function-widget-bound {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border: 0;
  margin: 0;
  font-family: monospace;
  font-size: medium;
  color: cyan;
}

.neuroglancer-transfer-function-window-bounds {
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/widget/transfer_function.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,YAAY;EACZ,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;EACT,SAAS;EACT,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":["/**\n * @license\n * Copyright 2024 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-transfer-function-panel {\n  height: 60px;\n  border: 1px solid #666;\n  margin-top: 5px;\n}\n\n.neuroglancer-transfer-function-color-picker {\n  text-align: right;\n}\n\n.neuroglancer-transfer-function-widget-bound {\n  background-color: transparent;\n  border-color: transparent;\n  box-shadow: none;\n  border: 0;\n  margin: 0;\n  font-family: monospace;\n  font-size: medium;\n  color: cyan;\n}\n\n.neuroglancer-transfer-function-window-bounds {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
