// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.range-slider {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-end;
}

.range-slider input[type="range"] {
  background: transparent;
  flex-basis: 0px;
}

.range-slider input[type="number"] {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: 0px;
}

.range-slider input[type="range"]::-moz-range-track {
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/widget/range.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,0CAA0C;EAC1C,WAAW;EACX,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["/**\n * @license\n * Copyright 2016 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.range-slider {\n  display: flex;\n  flex-direction: row;\n  white-space: nowrap;\n  justify-content: flex-end;\n}\n\n.range-slider input[type=\"range\"] {\n  background: transparent;\n  flex-basis: 0px;\n}\n\n.range-slider input[type=\"number\"] {\n  background-color: rgba(255, 255, 255, 0.3);\n  color: #fff;\n  border: 0px;\n}\n\n.range-slider input[type=\"range\"]::-moz-range-track {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
