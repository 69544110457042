// DO NOT EDIT: Generated by config/update_conditions.ts
import "#datasource/boss/register_default";
import "#datasource/boss/register_credentials_provider";
import "#datasource/brainmaps/register_default";
import "#datasource/brainmaps/register_credentials_provider";
import "#datasource/deepzoom/register_default";
import "#datasource/dvid/register_default";
import "#datasource/dvid/register_credentials_provider";
import "#datasource/graphene/register_default";
import "#datasource/middleauth/register_credentials_provider";
import "#datasource/n5/register_default";
import "#datasource/ngauth/register_credentials_provider";
import "#datasource/nggraph/register_default";
import "#datasource/nifti/register_default";
import "#datasource/obj/register_default";
import "#datasource/precomputed/register_default";
import "#datasource/render/register_default";
import "#datasource/vtk/register_default";
import "#datasource/zarr/register_default";
