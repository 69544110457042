// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-render-scale-widget {
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-render-scale-widget-prompt {
  white-space: nowrap;
}

.neuroglancer-render-scale-widget > canvas {
  height: 36px;
  flex: 1;
  flex-basis: 0px;
  width: 0px;
}

.neuroglancer-render-scale-widget-legend {
  width: 15ch;
  font-size: 11px;
  text-align: right;
}

.neuroglancer-render-scale-widget-legend > div {
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/widget/render_scale_widget.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,OAAO;EACP,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":["/**\n * @license\n * Copyright 2019 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-render-scale-widget {\n  margin-top: 2px;\n  margin-bottom: 2px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.neuroglancer-render-scale-widget-prompt {\n  white-space: nowrap;\n}\n\n.neuroglancer-render-scale-widget > canvas {\n  height: 36px;\n  flex: 1;\n  flex-basis: 0px;\n  width: 0px;\n}\n\n.neuroglancer-render-scale-widget-legend {\n  width: 15ch;\n  font-size: 11px;\n  text-align: right;\n}\n\n.neuroglancer-render-scale-widget-legend > div {\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
