// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-panel {
  flex: 1;
}

.neuroglancer-show-panel-borders .neuroglancer-panel {
  border-style: solid;
  border-color: black;
  border-width: 2px;
}

.neuroglancer-panel:focus-within {
  border-color: white;
}

.neuroglancer-layer-group-viewer {
  outline: 0px;
}

.neuroglancer-layer-group-viewer-context-menu {
  flex-direction: column;
  align-items: stretch;
}

.neuroglancer-layer-group-viewer-context-menu label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.neuroglancer-layer-group-viewer-context-menu select::before {
  flex: 1;
  content: " ";
}

.neuroglancer-layer-group-viewer-context-menu select {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/layer_group_viewer.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,OAAO;AACT;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,OAAO;EACP,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/**\n * @license\n * Copyright 2016 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-panel {\n  flex: 1;\n}\n\n.neuroglancer-show-panel-borders .neuroglancer-panel {\n  border-style: solid;\n  border-color: black;\n  border-width: 2px;\n}\n\n.neuroglancer-panel:focus-within {\n  border-color: white;\n}\n\n.neuroglancer-layer-group-viewer {\n  outline: 0px;\n}\n\n.neuroglancer-layer-group-viewer-context-menu {\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.neuroglancer-layer-group-viewer-context-menu label {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.neuroglancer-layer-group-viewer-context-menu select::before {\n  flex: 1;\n  content: \" \";\n}\n\n.neuroglancer-layer-group-viewer-context-menu select {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
