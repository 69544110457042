// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2020 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-invlerp-cdfpanel {
  height: 50px;
  cursor: ew-resize;
}

.neuroglancer-invlerp-cdfpanel,
.neuroglancer-invlerp-legend-panel {
  border: 1px solid #666;
}

.neuroglancer-invlerp-legend-panel {
  height: 15px;
}

.neuroglancer-invlerp-widget-bound {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border: 0;
  margin: 0;
  font-family: monospace;
  font-size: medium;
}

.neuroglancer-invlerp-widget-window-bound {
  color: cyan;
}

.neuroglancer-invlerp-widget-range-bound {
  color: white;
}

.neuroglancer-invlerp-widget-bounds {
  display: flex;
}

.neuroglancer-invlerp-widget-range-spacer {
  flex: 1;
  text-align: center;
}

.neuroglancer-invlerp-widget-window-bounds {
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/widget/invlerp.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;EACT,SAAS;EACT,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["/**\n * @license\n * Copyright 2020 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-invlerp-cdfpanel {\n  height: 50px;\n  cursor: ew-resize;\n}\n\n.neuroglancer-invlerp-cdfpanel,\n.neuroglancer-invlerp-legend-panel {\n  border: 1px solid #666;\n}\n\n.neuroglancer-invlerp-legend-panel {\n  height: 15px;\n}\n\n.neuroglancer-invlerp-widget-bound {\n  background-color: transparent;\n  border-color: transparent;\n  box-shadow: none;\n  border: 0;\n  margin: 0;\n  font-family: monospace;\n  font-size: medium;\n}\n\n.neuroglancer-invlerp-widget-window-bound {\n  color: cyan;\n}\n\n.neuroglancer-invlerp-widget-range-bound {\n  color: white;\n}\n\n.neuroglancer-invlerp-widget-bounds {\n  display: flex;\n}\n\n.neuroglancer-invlerp-widget-range-spacer {\n  flex: 1;\n  text-align: center;\n}\n\n.neuroglancer-invlerp-widget-window-bounds {\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
