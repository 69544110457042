// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-data-panel-layout-controls {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  align-items: start;
}

.neuroglancer-data-panel-layout-controls > button {
  display: flex;
  margin: 2px;
  border: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: row;
  align-self: top;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  font-size: 15px;
  width: 18px;
  height: 18px;
  color: white;
}

.neuroglancer-data-panel-layout-controls > button > div {
  display: inline-block;
  text-align: center;
  line-height: 12px;
  width: 15px;
  height: 15px;
}

.neuroglancer-data-panel-layout-controls > button:hover {
  color: #6ff;
}
`, "",{"version":3,"sources":["webpack://./src/data_panel_layout.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,WAAW;EACX,oCAAoC;EACpC,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;AACb","sourcesContent":["/**\n * @license\n * Copyright 2019 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-data-panel-layout-controls {\n  position: absolute;\n  top: 2px;\n  right: 2px;\n  display: flex;\n  align-items: start;\n}\n\n.neuroglancer-data-panel-layout-controls > button {\n  display: flex;\n  margin: 2px;\n  border: 0px;\n  background-color: rgba(0, 0, 0, 0.5);\n  flex-direction: row;\n  align-self: top;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid white;\n  font-size: 15px;\n  width: 18px;\n  height: 18px;\n  color: white;\n}\n\n.neuroglancer-data-panel-layout-controls > button > div {\n  display: inline-block;\n  text-align: center;\n  line-height: 12px;\n  width: 15px;\n  height: 15px;\n}\n\n.neuroglancer-data-panel-layout-controls > button:hover {\n  color: #6ff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
