// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-annotation-rendering-tab {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.neuroglancer-annotation-rendering-tab .neuroglancer-shader-code-widget {
  flex-shrink: 0;
}

.neuroglancer-annotation-shader-property-list {
  max-height: 8em;
  overflow: auto;
  flex-shrink: 0;
}

.neuroglancer-annotation-shader-property {
  white-space: pre;
  font-family: monospace;
  font-size: medium;
}

.neuroglancer-annotation-shader-property-type {
  color: #c6c;
  margin-right: 1ch;
}

.neuroglancer-annotation-shader-property-identifier {
  user-select: text;
}

.neuroglancer-annotation-shader-property-identifier::after {
  content: "()";
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/layer/annotation/style.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;;AAEF;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;AACb","sourcesContent":["/**\n * @license\n * Copyright 2016 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n\n.neuroglancer-annotation-rendering-tab {\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.neuroglancer-annotation-rendering-tab .neuroglancer-shader-code-widget {\n  flex-shrink: 0;\n}\n\n.neuroglancer-annotation-shader-property-list {\n  max-height: 8em;\n  overflow: auto;\n  flex-shrink: 0;\n}\n\n.neuroglancer-annotation-shader-property {\n  white-space: pre;\n  font-family: monospace;\n  font-size: medium;\n}\n\n.neuroglancer-annotation-shader-property-type {\n  color: #c6c;\n  margin-right: 1ch;\n}\n\n.neuroglancer-annotation-shader-property-identifier {\n  user-select: text;\n}\n\n.neuroglancer-annotation-shader-property-identifier::after {\n  content: \"()\";\n  color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
