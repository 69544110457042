// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.neuroglancer-icon {
  display: inline-flex;
  text-decoration: none;
  align-self: center;
  white-space: nowrap;
  padding-left: 2px;
  padding-right: 2px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 20%;
  align-items: center;
  justify-content: center;
  color: white;
  font: 12px sans-serif;
  cursor: pointer;
  font-weight: 900;
  margin-left: 1px;
  margin-right: 1px;
  user-select: none;
}

.neuroglancer-icon svg {
  width: 16px;
  height: 16px;
  fill: transparent;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.neuroglancer-icon:hover {
  background-color: #db4437;
}

.neuroglancer-icon-hover:not(:hover) svg:last-child {
  display: none;
}

.neuroglancer-icon-hover:hover svg:first-child {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/widget/icon.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;EAcE;AACF;EACE,oBAAoB;EACpB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,eAAe;EACf,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":["/**\n * @license\n * Copyright 2018 Google Inc.\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *      http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n */\n.neuroglancer-icon {\n  display: inline-flex;\n  text-decoration: none;\n  align-self: center;\n  white-space: nowrap;\n  padding-left: 2px;\n  padding-right: 2px;\n  min-width: 18px;\n  min-height: 18px;\n  border-radius: 20%;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  font: 12px sans-serif;\n  cursor: pointer;\n  font-weight: 900;\n  margin-left: 1px;\n  margin-right: 1px;\n  user-select: none;\n}\n\n.neuroglancer-icon svg {\n  width: 16px;\n  height: 16px;\n  fill: transparent;\n  stroke: white;\n  stroke-width: 2;\n  stroke-linecap: round;\n  stroke-linejoin: round;\n}\n\n.neuroglancer-icon:hover {\n  background-color: #db4437;\n}\n\n.neuroglancer-icon-hover:not(:hover) svg:last-child {\n  display: none;\n}\n\n.neuroglancer-icon-hover:hover svg:first-child {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
